import styled from "@emotion/styled";

const size = {
  mobileS: '320px',
  mobileM: '375px',
  mobileL: '425px',
  tablet: '768px',
  laptop: '1024px',
  laptopL: '1440px',
  desktop: '2560px'
}

export const device = {
  mobile: `(min-width: ${size.mobileS}) and (max-width: 1023px)`,
  laptop: `(min-width: ${size.laptop})`
};


export const SiteBox = styled.div`
  
  margin: 0;
  padding: 0;
  display: flex;
  flex-direction: row;
  justify-content: center;
  background-color: #000;

  @media ${device.mobile} { 
    display: flex;
    flex-direction: column;
    justify-content: flex-start;

  }

  @media ${device.laptop} { 

  }

`;

export const SiteColumn = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  width:33.33%;

  @media ${device.mobile} { 
    width: 100%;
  }


`

export const PersonalArea = styled.div`
  height: 665px;
  color: #dad2d8;
  font-family: "Inter";

  display: flex;
  flex-direction: column;

  @media ${device.mobile} {
    max-width: 32rem; 
    border: 1px #dad2d8 solid;
    border-radius: 4rem;
    margin-top: 1rem;
  }

  @media ${device.laptop} { 

    border: 1px #dad2d8 solid;
    border-radius: 40px;
    width: 350px;
    height: 565px;

    position: fixed;
    z-index: 999;
    left: 2rem;
    top: 50%;
    transform: translateY(-50%);
    overflow: hidden;
    padding: 6px;

  }
  
`;

export const PersonalLogo = styled.div`
  font-family: "Inter";
  font-weight: 800;
  font-size: 35px;
  letter-spacing: -2px;

  margin-top: 50px;
  text-align: center;
  
`;

export const PersonalAvatarContainer = styled.div`
  text-align: center;
`;

export const PersonalAvatar = styled.img`
  width: 240px; /* Ajuste conforme necessário */
  height: 240px; /* Ajuste conforme necessário */
  border-radius: 10%;
  margin-top: 10px;
  margin-bottom: 25px;
  object-fit: cover;

  @media ${device.laptop} { 
    width: 190px; /* Ajuste conforme necessário */
    height: 190px;
  }
`;

export const PersonalContato = styled.div`
  text-align: center;
  font-family: "Inter";
  font-size: 24px;
  font-weight: 300;
  p {
    margin: 10px;
  }

  p .localidade {
    font-size: 20px;
  }

  @media ${device.laptop} { 
    font-size: 22px;
  }
`;

export const PersonalSocialBar = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
`;  

export const PersonalSocialIMG = styled.div`
  margin: 40px 20px;
  color: #dad2d8;
  text-decoration: none;
  a{
    color: #dad2d8;
  }
`;

export const PersonalCopyRight = styled.div`
  margin: 5px 20px;
  text-align: center;

  @media ${device.laptop} { 
    font-size: 12px;
  }
`;

export const CentralArea = styled.div`
  font-family: "Inter";
  display: flex;
  justify-content: center;
  
`;

export const Content = styled.div`
  background-color: #000;
  font-family: "Inter", sans-serif;
  color: #fff;

  display: flex;
  flex-direction: column;
  justify-content: flex-start;


  @media ${device.mobile} {
    width:100%;

  }

  @media ${device.laptop} {
    min-width:63rem;
    height: 100vh;

    &.longHeight{
      height: 930px;
    }

  }
`;

export const TitleArea = styled.div`

  @media ${device.mobile} {
    padding: 2rem;
    width:100%;
  }

  @media ${device.laptop} {
    margin-top: 10rem;
  }
  
`

export const ContentTitle = styled.div`
  margin-bottom: 30px;
  h1 {
    font-size: 48px;
    letter-spacing: -0.2px;
    font-family: "Inter", sans-serif;
    font-weight: 300;
    line-height: 70px;
  }

  @media ${device.mobile} {
    margin-bottom: 1rem;

    h1{
      line-height: 4.5rem;
      font-size: 3.6rem;
      font-weight: 500;
    }

  }

  @media ${device.laptop} { 
    h1{
      font-size: 48px;
    }
  }
`;

export const ContentSubtitle = styled.div`
  h3 {
    font-size: 18px;
    letter-spacing: -0.2px;
    font-family: "Inter", sans-serif;
    font-weight: 400;
    line-height: 27.2px;

    color: #dad2d8;
  }
`

export const ContentIndicadorArea = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  margin-top: 70px;

  @media ${device.mobile} {
    margin-top: 40px;
    margin-bottom: 50px;

  }


  @media ${device.laptop} { 
    margin-left: 10px;
  }
`;

export const ContentIndicador = styled.div`
  display: flex;
  flex-direction: column;
  color: #06d6a0;
  text-align: center;

  h1 {
    font-size: 58px;
    font-weight: 500;
    letter-spacing: -0.2px;
    font-family: "Inter", sans-serif;
    margin: 0px;
  }

  h3 {
    font-size: 16px;
    letter-spacing: -0.2px;
    font-family: "Inter", sans-serif;
    font-weight: 400;
    line-height: 7.2px;
    margin: 0px;
    color: #06d6a0;
  }
`;

export const ContentTexto = styled.div`
  h3 {
    font-size: 16px;
    letter-spacing: -0.2px;
    font-family: "Inter", sans-serif;
    font-weight: 400;
    line-height: 25px;
    margin: 0px;
    color: #dad2d8;
  }

  h3 p {
    padding-bottom: 10px;
  }

  @media ${device.mobile} { 

    h3 {
      padding: 0rem 2rem 2rem 2rem;
      
    }
  }

  @media ${device.laptop} { 
  }
`;

export const ContentBooksArea = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  flex-wrap: wrap;
  
  @media ${device.mobile} { 
    margin-top: 40px;
  }

  @media ${device.laptop} { 
    margin-top: 11rem;
  }

`;

export const ContentBook = styled.div`
  width: 20rem;
  text-align: center; 
 
  @media ${device.mobile} { 
    margin: 1rem;

  }

  @media ${device.laptop} { 
    margin-top: 1rem;
  }
`;

export const MenuLateralArea = styled.div`
  border: 1px #ec9a29 solid;
  border-radius: 40px;
  width: 70px;
  height: 200px;

  position: fixed;
  z-index: 999;
  right: 5px;
  top: 50%;
  transform: translateY(-50%);
  overflow: hidden;
  padding: 6px;

  display: flex;
  flex-direction: column;
  justify-content:center;
  margin-right: 80px;

  color: #fff;

  @media ${device.mobile} { 
    display: none;

  }
`;

export const BtnArea = styled.div`
  padding: 10px;
  cursor: pointer;
  text-align: center;
`

