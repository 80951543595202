import React, { useCallback, useRef } from "react";
import { Grid, styled, Paper, Button } from "@mui/material";
import Tooltip from '@mui/material/Tooltip';

import {
  PersonalArea,
  SiteBox,
  SiteColumn,
  MenuLateralArea,
  CentralArea,
  Content,
  PersonalLogo,
  PersonalAvatar,
  PersonalAvatarContainer,
  PersonalContato,
  PersonalSocialBar,
  PersonalSocialIMG,
  PersonalCopyRight,
  ContentTitle,
  ContentIndicadorArea,
  ContentIndicador,
  ContentTexto,
  ContentBooksArea,
  ContentBook,
  TitleArea,
  ContentSubtitle,
  BtnArea,
} from "./styles";
import "./styles.css";

import { Telegram, Instagram, X, Home, WbSunny, LibraryBooks} from "@mui/icons-material";

export const Site = () => {
  const scroolPage = useCallback((alvo: string) => {
    const destino = document.getElementById(alvo);
    console.log("2->", destino);
    destino?.scrollIntoView({ behavior: "smooth" });
  }, []);

  const scrollToLastFruit = () => {};

  const ref = useRef(null);

  return (
    <SiteBox>
      <SiteColumn>
        <CentralArea>
          <PersonalArea>
            <PersonalLogo>.damasiodaaf</PersonalLogo>
            <PersonalAvatarContainer>
              <PersonalAvatar src="avatar.jpeg" alt="Avatar damasiodaaf" />
            </PersonalAvatarContainer>
            <PersonalContato>
              <p>damasiodaaf@gmail.com</p>
              <p className="localidade"> Rio de Janeiro - Brasil</p>
            </PersonalContato>

            <PersonalSocialBar>
              <PersonalSocialIMG>
                <a href="https://t.me/grupodamasiodaaf" target="_blank">
                  <Telegram fontSize="large" />
                </a>
              </PersonalSocialIMG>

              <PersonalSocialIMG>
                <a href="https://www.instagram.com/damasiodaaf/" target="_blank">
                  <Instagram fontSize="large"/>
                </a>
              </PersonalSocialIMG>

              {/* <PersonalSocialIMG>
                <X fontSize="large"/>
              </PersonalSocialIMG> */}
            </PersonalSocialBar>
            <PersonalCopyRight>&#169;2024 damasiodaaf </PersonalCopyRight>
          </PersonalArea>
        </CentralArea>
      </SiteColumn>

      <SiteColumn>
        <Content id="1">
          <TitleArea>
            <ContentTitle>
              <h1>
                <p>Aqui celebramos cada passo na jornada rumo ao sucesso!</p>
              </h1>
            </ContentTitle>
            <ContentSubtitle>
              <h3>
                <p>Persiga seus sonhos com determinação.</p>
                <p>Cada passo é uma vitória e cada desafio é uma oportunidade!</p>
              </h3>
            </ContentSubtitle>
          </TitleArea>
          
          <ContentIndicadorArea>
            <ContentIndicador>
              <h1>04</h1>
              <h3>livros</h3>
            </ContentIndicador>
            <ContentIndicador>
              <h1>875+</h1>
              <h3>páginas lidas</h3>
            </ContentIndicador>
          </ContentIndicadorArea>
        </Content>

        <Content className='longHeight' id="2">
          <TitleArea>
            <ContentTitle>
              <h1>
                <p>É importante que você tenha um motivo. O meu motivo é o Romeu!</p>
              </h1>
            </ContentTitle>
            
          </TitleArea>
          <ContentTexto>
            <h3>
              <p>
                Sabemos que, de maneira geral, a educação escolar no Brasil é
                péssima, mas se manter refém dessa realidade é uma escolha
                pessoal e extremamente particular.
              </p>
              <p>
                Sempre sonhei em ser um bom pai, e desde o primeiro dia, o Romeu
                tem sido para mim uma fonte inesgotável de inspiração, energia
                de vida e, principalmente, o motivo pelo qual eu tenho buscado
                me tornar uma pessoa melhor.
              </p>
              <p>
                Certo dia, estava fazendo uma autoanálise para saber no que, de
                fato, eu poderia melhorar para me oferecer para o Romeu. Eu não
                estava buscando uma melhoria grandiosa que fosse percebível à
                distância. Eu estava buscando algo pequeno, mas que pudesse, no
                fim das contas, revolucionar a nossa história e experiência de
                pai e filho.
              </p>
              <p>
                Nessa busca, eu identifiquei a leitura. Meu Deus! Olha há quanto
                tempo eu não leio nada que não seja um conteúdo técnico sobre
                alguma coisa! Foi a partir desse momento que eu comecei a ler.
              </p>
              <p>
                O processo de reversão dessa atrofia não é simples, mas é
                prazeroso perceber que, com o passar do tempo, você consegue, de
                fato, ler, entender, aprender e ler mais sem se cansar.
              </p>
              <p>
                Para mim, a leitura (re)surgiu como forma de ser um pai melhor
                para o meu filho. Hoje, a leitura é uma das ferramentas que
                utilizo para me tornar uma pessoa melhor.
              </p>
              <p>
                <b>Romeu, muito obrigado!</b>
              </p>
            </h3>
          </ContentTexto>
        </Content>

        <Content id="3">
          <ContentBooksArea>
            <ContentBook>
              <p><img src="./cientista_de_marketing.png" alt="livro cinetista de marketing" width={145}/></p>
            </ContentBook>
            
            <ContentBook>
              <p><img src="./segredos_com.png" alt="Segredos.com" width={145}/></p>
            </ContentBook>
            
            <ContentBook>
              <p><img src="./segredos_do_trafego.png" alt="segredos_do_tráfego.com" width={145}/></p>
            </ContentBook>
            
            <ContentBook>
              <p><img src="./quem_pensa.png" alt="quem pensa enriquece" width={145}/></p>
            </ContentBook>
          </ContentBooksArea>
        </Content>


      </SiteColumn>
      
      <SiteColumn>
        <MenuLateralArea>
          
          <BtnArea 
            className="menuItem" 
            onClick={() => {
              scroolPage("1");
            }}>
              <Tooltip title="Home" arrow placement="left">
                <Home fontSize="large" sx={{ color: 'ff00ff' }}/>
              </Tooltip>
            
          </BtnArea>

          <BtnArea 
            className="menuItem" 
            onClick={() => {
              scroolPage("2");
            }}>
              <Tooltip title="Motivação" arrow placement="left">
                <WbSunny fontSize="large" sx={{ color: 'ff00ff' }}/>
              </Tooltip>
          </BtnArea>
          
          <BtnArea 
            className="menuItem" 
            onClick={() => {
              scroolPage("3");
            }}>
              <Tooltip title="Livros" arrow placement="left">
                <LibraryBooks fontSize="large" sx={{ color: 'ff00ff' }}/>
              </Tooltip>
          </BtnArea>

        </MenuLateralArea>
      </SiteColumn>

      
    </SiteBox>
  );
};

export default Site;
